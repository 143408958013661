import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import Caption from '../components/caption';
import NextProj from '../components/nextproject';

import Layout from '../components/layout'
import '../components/layout.css'
import '../components/projectCards.css'



const Feeln = (props) => (
  <Layout>
      <Helmet
      title="Juan Herrera — Feeln"
      meta={[
        { name: 'description', content: 'Juan Herrera — Product designer' },
        { name: 'keywords', content: 'product, design, ui, ux' },
      ]}
      />
    <div className="casestudies">
      <div className="casestudyTop">
      <div className="back"><p><Link to="/">Back</Link></p></div>
        <div className="topcontainer">
          <div className="casestudyintro">
            <h2>Feeln</h2>
            <p>Feeln (currently known as Hallmark Movies Now) was a streaming service that offered family-friendly content—including original TV series as well as Hollywood blockbusters and classic movies—on almost any device for a low monthly subscription. STRV worked with Feeln to develop its apps across all devices.</p>
          </div>
          <div id="CSinfo">
            <div className="projectInfo"><h5>Role</h5>
              <p>UI Designer<br /></p>
              <h5>Platform</h5>
              <p>Android, Fire TV</p>
              <h5>Year</h5>
              <p>2015</p>
            </div>
          </div>
        </div>
      </div>
        <div className="desktopImages">
              <Img alt="Feeln Hero" fluid={props.data.feeln1.childImageSharp.fluid} />
        </div>
        <Caption captiontext="Feeln — Movie detail"/>
      <div className="dividers">Overview</div>
        <div className="casestudyContent">
            <div className="CSleftCol">
              <p>This was one of the higher-profile client projects I was involved with during my time at STRV. I was brought into the project at a later phase, after a multitude of explorations were done for the iOS version, in order to bring Feeln to the (then new-ish) Material Design design language.</p>
              </div>
            <div className="CSrightCol">
              <p>This was especially exciting as it was amongst the first few Material-oriented projects we had the oportunity to work on. Needless to say, the design language that was established for iOS worked really well in the colourful world of Material Design.</p>
            </div>
        </div>
        <div className="desktopImages">
              <Img alt="Feeln wires 1" fluid={props.data.feeln4.childImageSharp.fluid} />
        </div>
        <div className="desktopImages">
              <Img alt="Feeln wires 2" fluid={props.data.feeln5.childImageSharp.fluid} />
        </div>
        <Caption captiontext="Feeln — Assorted wireframes"/>
           <div className="desktopImages">
              <Img alt="Feeln screens" fluid={props.data.feeln2.childImageSharp.fluid} />
           </div>
           <Caption captiontext="Feeln — Assorted Android screens"/>
           <div className="desktopImages">
              <Img alt="Feeln for Tablets" fluid={props.data.feeln6.childImageSharp.fluid} />
           </div>
           <Caption captiontext="Feeln — Tablet"/>
           <div className="desktopImages">
              <Img alt="Feeln TV" fluid={props.data.feeln3.childImageSharp.fluid} />
           </div>
           <Caption captiontext="Feeln — Assorted Fire TV screens"/>
           <NextProj
              nextproj='Live Share'
              projdesc='Collaborative coding.'
              nextlink='/vsls'
              />        
        </div>
  </Layout>
)

export default Feeln


// Image stuff

export const pageQuery = graphql`
  query {
    feeln1: file(relativePath: { eq: "feeln-01.png" }) {
      ...fluidImage
    }
    feeln2: file(relativePath: { eq: "feeln-02.jpg" }) {
      ...fluidImage
    }
    feeln3: file(relativePath: { eq: "feeln-03.jpg" }) {
      ...fluidImage
    }
    feeln4: file(relativePath: { eq: "feeln-04.png" }) {
      ...fluidImage
    }
    feeln5: file(relativePath: { eq: "feeln-05.png" }) {
      ...fluidImage
    }
    feeln6: file(relativePath: { eq: "feeln-06.jpg" }) {
      ...fluidImage
    }
  }
`
